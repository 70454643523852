/**
 * Constants for types
 */
export const DATE_TIME_SERVER_PARSE_FORMATS = [
  'DD, MMM YYYY - HH:mm:ss',
  'DD, MMM YYYY - HH:mm:ssZ',
  'DD, MMM YYYY',
];
export const DATE_TIME_SERVER_ACCEPT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_TIME_LOCAL_ENTITY_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ';
export const DATE_TIME_PICKER_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const URL_DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_FORMAT_DB = 'YYYY-MM-DD';
export const DATE_FORMAT_NO_YEAR = 'DD/MM';
export const DATE_FORMAT_NO_YEAR_WITH_DAY = 'DD/MM (dd)';
export const DATE_TIME_SEC_FORMAT = 'YYYY-MM-DD HH:mm:ss';
// for parsing
//export const ACCEPTED_TIME_FORMATS=[ 'HH:mm:ss', 'HH:mm']
// for formatting
export const TIME_FORMAT = 'HH:mm';
export const TIME_SEC_FORMAT = 'HH:mm:ss';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const DATE_TIME_FORMAT_24H = `${DATE_FORMAT} - ${TIME_FORMAT}`;
export const TIME_DATE_FORMAT = `${TIME_FORMAT} ${DATE_FORMAT}`;
export const TIME_SEC_DATE_FORMAT = `${TIME_SEC_FORMAT} ${DATE_FORMAT}`;
export const TIME_REGEX = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
export const DURATION_HHMM_REGEX = /^([0-9]?[0-9]):[0-5][0-9]$/;
export const BANK_ACCOUNT_REGEX = /^(\d){8}$/;
export const SORT_CODE_REGEX = /^[0-9]{2}[-][0-9]{2}[-][0-9]{2}$/;
export const EMAIL_REGEX =
  // eslint-disable-next-line max-len, no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const CURRENCY_REGEX = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
export const CURRENCY_REGEX_FROM_ZERO = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;
export const UNSIGNED_INTEGER_STRING_REGEX = /[0-9]+/;

export const DAY_NAMES_MAP = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};
